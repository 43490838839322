import * as extrasApi from '@/api/extras/extras'

const extras = {
  state: {
  },
  mutations: {
  },
  actions: {
    getExtras ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extrasApi.getExtras(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showExtra ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extrasApi.showExtra(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeExtra ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extrasApi.storeExtra(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateExtra ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extrasApi.updateExtra(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyExtra ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extrasApi.destroyExtra(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default extras
