import request from '@/utils/request'

export function getHolidayDates (data) {
  return request({
    url: '/api/admin/holiday-dates',
    method: 'post',
    data: data
  })
}

export function storeHolidayDate (data) {
  return request({
    url: '/api/admin/holiday-dates/store',
    method: 'post',
    data: data
  })
}

export function destroyHolidayDate (data) {
  return request({
    url: '/api/admin/holiday-dates/destroy',
    method: 'post',
    data: data
  })
}
