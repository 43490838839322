import * as configsApi from '@/api/configs/configs'

const configs = {
  state: {
  },
  mutations: {
  },
  actions: {
    getConfigs ({ commit }, data) {
      return new Promise((resolve, reject) => {
        configsApi.getConfigs(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showConfig ({ commit }, data) {
      return new Promise((resolve, reject) => {
        configsApi.showConfig(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateConfig ({ commit }, data) {
      return new Promise((resolve, reject) => {
        configsApi.updateConfig(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default configs
