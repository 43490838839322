import request from '@/utils/request'

export function getRooms (data) {
  return request({
    url: '/api/admin/rooms',
    method: 'post',
    data: data
  })
}

export function showRoom (data) {
  return request({
    url: '/api/admin/rooms/show',
    method: 'post',
    data: data
  })
}

export function storeRoom (data) {
  return request({
    url: '/api/admin/rooms/store',
    method: 'post',
    data: data
  })
}

export function updateRoom (data) {
  return request({
    url: '/api/admin/rooms/update',
    method: 'post',
    data: data
  })
}

export function destroyRoom (data) {
  return request({
    url: '/api/admin/rooms/destroy',
    method: 'post',
    data: data
  })
}
