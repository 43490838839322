import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang/i18n'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const dashboard = () => import('@/views/dashboard/dashboard')
const profile = () => import('@/views/profile')

const organization_admins = () => import('@/views/organization/admins')

const dates_bookingDates = () => import('@/views/dates/bookingDates')
const dates_holidayDates = () => import('@/views/dates/holidayDates')
const configs_configs = () => import('@/views/configs/configs')
const rooms_roomsSetting = () => import('@/views/rooms/roomsSetting')
const rooms_lockRoomSetting = () => import('@/views/rooms/lockRoomSetting')
const extras_extrasSetting = () => import('@/views/extras/extrasSetting')
const orders_orders = () => import('@/views/orders/orders')
const orders_orderRooms = () => import('@/views/orders/orderRooms')
const orders_orderExtras = () => import('@/views/orders/orderExtras')
const contents_contents = () => import('@/views/contents/contents')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: i18n.t('main'),
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('dashboard'),
          component: dashboard
        },
        {
          path: 'profile',
          name: i18n.t('profile'),
          component: profile
        },
        {
          path: 'organization',
          redirect: '/organization/admins',
          name: i18n.t('organization_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'admins',
              name: i18n.t('accounts'),
              component: organization_admins,
              meta: {
                role: 'admins'
              }
            }
          ]
        },
        {
          path: 'dates',
          redirect: '/dates/booking-dates',
          name: i18n.t('date_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'booking-dates',
              name: i18n.t('booking_dates'),
              component: dates_bookingDates,
              meta: {
                role: 'dates.booking-dates'
              }
            },
            {
              path: 'holiday-dates',
              name: i18n.t('holiday_dates'),
              component: dates_holidayDates,
              meta: {
                role: 'dates.holiday-dates'
              }
            },
          ]
        },
        {
          path: 'configs',
          name: i18n.t('configs'),
          component: configs_configs,
          meta: {
            role: 'configs'
          }
        },
        {
          path: 'rooms',
          redirect: '/rooms/rooms-setting',
          name: i18n.t('room_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'rooms-setting',
              name: i18n.t('room_setting'),
              component: rooms_roomsSetting,
              meta: {
                role: 'rooms.rooms-setting'
              }
            },
            {
              path: 'lock-room-setting',
              name: i18n.t('lock_room_setting'),
              component: rooms_lockRoomSetting,
              meta: {
                role: 'rooms.lock-room-setting'
              }
            },
          ]
        },
        {
          path: 'extras',
          redirect: '/extras/extras-setting',
          name: i18n.t('extra_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'extras-setting',
              name: i18n.t('extra_setting'),
              component: extras_extrasSetting,
              meta: {
                role: 'extras.extras-setting'
              }
            },
          ]
        },
        {
          path: 'orders',
          redirect: '/orders/orders',
          name: i18n.t('order_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'orders',
              name: i18n.t('order_search'),
              component: orders_orders,
              meta: {
                role: 'orders.orders'
              }
            },
            {
              path: 'order-rooms',
              name: i18n.t('order_room_search'),
              component: orders_orderRooms,
              meta: {
                role: 'orders.order-rooms'
              }
            },
            {
              path: 'order-extras',
              name: i18n.t('order_extra_search'),
              component: orders_orderExtras,
              meta: {
                role: 'orders.order-extras'
              }
            },
          ]
        },
        {
          path: 'contents',
          name: i18n.t('content_management'),
          component: contents_contents,
          meta: {
            role: 'contents'
          }
        },
      ]
    },
    {
      path: '*',
      redirect: '/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

