import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import getters from '@/store/getters'

import admins from '@/store/modules/admins/admins'
import dashboard from '@/store/modules/dashboard/dashboard'
import bookingOpenDates from '@/store/modules/bookingOpenDates/bookingOpenDates'
import bookingDates from '@/store/modules/bookingDates/bookingDates'
import holidayDates from '@/store/modules/holidayDates/holidayDates'
import holidayTypes from '@/store/modules/holidayTypes/holidayTypes'
import configs from '@/store/modules/configs/configs'
import rooms from '@/store/modules/rooms/rooms'
import roomImages from '@/store/modules/rooms/roomImages'
import roomOptions from '@/store/modules/rooms/roomOptions'
import roomLocks from '@/store/modules/rooms/roomLocks'
import extras from '@/store/modules/extras/extras'
import extraImages from '@/store/modules/extras/extraImages'
import extraOptions from '@/store/modules/extras/extraOptions'
import orders from '@/store/modules/orders/orders'
import orderRooms from '@/store/modules/orders/orderRooms'
import orderExtras from '@/store/modules/orders/orderExtras'
import contents from '@/store/modules/contents/contents'

// TinyMCE
import tinymce from '@/store/modules/tinymce/tinymce'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admins,
    dashboard,
    bookingOpenDates,
    bookingDates,
    holidayDates,
    holidayTypes,
    configs,
    rooms,
    roomImages,
    roomOptions,
    roomLocks,
    extras,
    extraImages,
    extraOptions,
    orders,
    orderRooms,
    orderExtras,
    contents,
    // TinyMCE
    tinymce,
  },
  state,
  mutations,
  getters
})
