import request from '@/utils/request'

export function getRoomLocks (data) {
  return request({
    url: '/api/admin/room-locks',
    method: 'post',
    data: data
  })
}

export function storeRoomLock (data) {
  return request({
    url: '/api/admin/room-locks/store',
    method: 'post',
    data: data
  })
}

export function destroyRoomLock (data) {
  return request({
    url: '/api/admin/room-locks/destroy',
    method: 'post',
    data: data
  })
}
