import request from '@/utils/request'

export function getContents (data) {
  return request({
    url: '/api/admin/contents',
    method: 'post',
    data: data
  })
}

export function showContent (data) {
  return request({
    url: '/api/admin/contents/show',
    method: 'post',
    data: data
  })
}

export function updateContent (data) {
  return request({
    url: '/api/admin/contents/update',
    method: 'post',
    data: data
  })
}
