import * as contentsApi from '@/api/contents/contents'

const contents = {
  state: {
  },
  mutations: {
  },
  actions: {
    getContents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        contentsApi.getContents(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showContent ({ commit }, data) {
      return new Promise((resolve, reject) => {
        contentsApi.showContent(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateContent ({ commit }, data) {
      return new Promise((resolve, reject) => {
        contentsApi.updateContent(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default contents
