import * as holidayDatesApi from '@/api/holidayDates/holidayDates'

const holidayDates = {
  state: {
  },
  mutations: {
  },
  actions: {
    getHolidayDates ({ commit }, data) {
      return new Promise((resolve, reject) => {
        holidayDatesApi.getHolidayDates(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeHolidayDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        holidayDatesApi.storeHolidayDate(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyHolidayDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        holidayDatesApi.destroyHolidayDate(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default holidayDates
