import request from '@/utils/request'

export function getExtras (data) {
  return request({
    url: '/api/admin/extras',
    method: 'post',
    data: data
  })
}

export function showExtra (data) {
  return request({
    url: '/api/admin/extras/show',
    method: 'post',
    data: data
  })
}

export function storeExtra (data) {
  return request({
    url: '/api/admin/extras/store',
    method: 'post',
    data: data
  })
}

export function updateExtra (data) {
  return request({
    url: '/api/admin/extras/update',
    method: 'post',
    data: data
  })
}

export function destroyExtra (data) {
  return request({
    url: '/api/admin/extras/destroy',
    method: 'post',
    data: data
  })
}
