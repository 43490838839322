import * as roomLocksApi from '@/api/rooms/roomLocks'

const roomLocks = {
  state: {
  },
  mutations: {
  },
  actions: {
    getRoomLocks ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomLocksApi.getRoomLocks(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeRoomLock ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomLocksApi.storeRoomLock(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyRoomLock ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomLocksApi.destroyRoomLock(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default roomLocks
