import request from '@/utils/request'

export function getRoomImages (data) {
  return request({
    url: '/api/admin/room-images',
    method: 'post',
    data: data
  })
}

export function updateRoomImages (data) {
  return request({
    url: '/api/admin/room-images/update',
    method: 'post',
    data: data
  })
}
