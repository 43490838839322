import request from '@/utils/request'

export function getExtraOptions (data) {
  return request({
    url: '/api/admin/extra-options',
    method: 'post',
    data: data
  })
}

export function showExtraOption (data) {
  return request({
    url: '/api/admin/extra-options/show',
    method: 'post',
    data: data
  })
}

export function storeExtraOption (data) {
  return request({
    url: '/api/admin/extra-options/store',
    method: 'post',
    data: data
  })
}

export function updateExtraOption (data) {
  return request({
    url: '/api/admin/extra-options/update',
    method: 'post',
    data: data
  })
}

export function destroyExtraOption (data) {
  return request({
    url: '/api/admin/extra-options/destroy',
    method: 'post',
    data: data
  })
}
