import request from '@/utils/request'

export function uploadFile (data) {
  return request({
    url: '/api/admin/upload/upload-file',
    method: 'post',
    data: data
  })
}

export function uploadFiles (data) {
  return request({
    url: '/api/admin/upload/upload-files',
    method: 'post',
    data: data
  })
}
