import * as roomOptionsApi from '@/api/rooms/roomOptions'

const roomOptions = {
  state: {
  },
  mutations: {
  },
  actions: {
    getRoomOptions ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomOptionsApi.getRoomOptions(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showRoomOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomOptionsApi.showRoomOption(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeRoomOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomOptionsApi.storeRoomOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateRoomOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomOptionsApi.updateRoomOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyRoomOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomOptionsApi.destroyRoomOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default roomOptions
