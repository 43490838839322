import * as roomsApi from '@/api/rooms/rooms'

const rooms = {
  state: {
  },
  mutations: {
  },
  actions: {
    getRooms ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomsApi.getRooms(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomsApi.showRoom(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomsApi.storeRoom(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomsApi.updateRoom(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyRoom ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomsApi.destroyRoom(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default rooms
