import * as roomImagesApi from '@/api/rooms/roomImages'

const roomImages = {
  state: {
  },
  mutations: {
  },
  actions: {
    getRoomImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomImagesApi.getRoomImages(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateRoomImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        roomImagesApi.updateRoomImages(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default roomImages
