import * as extraImagesApi from '@/api/extras/extraImages'

const extraImages = {
  state: {
  },
  mutations: {
  },
  actions: {
    getExtraImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraImagesApi.getExtraImages(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateExtraImages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraImagesApi.updateExtraImages(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default extraImages
