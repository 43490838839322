import * as extraOptionsApi from '@/api/extras/extraOptions'

const extraOptions = {
  state: {
  },
  mutations: {
  },
  actions: {
    getExtraOptions ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraOptionsApi.getExtraOptions(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showExtraOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraOptionsApi.showExtraOption(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeExtraOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraOptionsApi.storeExtraOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateExtraOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraOptionsApi.updateExtraOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyExtraOption ({ commit }, data) {
      return new Promise((resolve, reject) => {
        extraOptionsApi.destroyExtraOption(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default extraOptions
