import request from '@/utils/request'

export function getBookingDates (data) {
  return request({
    url: '/api/admin/booking-dates',
    method: 'post',
    data: data
  })
}

export function showBookingDate (data) {
  return request({
    url: '/api/admin/booking-dates/show',
    method: 'post',
    data: data
  })
}

export function storeBookingDate (data) {
  return request({
    url: '/api/admin/booking-dates/store',
    method: 'post',
    data: data
  })
}

export function updateBookingDate (data) {
  return request({
    url: '/api/admin/booking-dates/update',
    method: 'post',
    data: data
  })
}

export function destroyBookingDate (data) {
  return request({
    url: '/api/admin/booking-dates/destroy',
    method: 'post',
    data: data
  })
}
