import * as dashboardApi from '@/api/dashboard/dashboard'

const dashboard = {
  state: {
  },
  mutations: {
  },
  actions: {
    //

  }
}

export default dashboard
