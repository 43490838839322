import request from '@/utils/request'
import md5 from 'md5'

export function login (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/api/admin/admins/login',
    method: 'post',
    data: data
  })
}

export function logout () {
  return request({
    url: '/api/admin/admins/logout',
    method: 'post'
  })
}

export function checkSession () {
  return request({
    url: '/api/admin/admins/check-session',
    method: 'post'
  })
}

export function updateAdminProfile (data) {
  return request({
    url: '/api/admin/admins/update-admin-profile',
    method: 'post',
    data: data
  })
}

export function updateAdminLocale (data) {
  return request({
    url: '/api/admin/admins/update-admin-locale',
    method: 'post',
    data: data
  })
}

export function updateAdminPassword (data) {
  data.current_password = data.current_password ? md5(data.current_password).toUpperCase() : ''
  data.new_password = data.new_password ? md5(data.new_password).toUpperCase() : ''
  data.new_password_confirmation = data.new_password_confirmation ? md5(data.new_password_confirmation).toUpperCase() : ''
  return request({
    url: '/api/admin/admins/update-admin-password',
    method: 'post',
    data: data
  })
}

export function updateAdminAvatar (data) {
  return request({
    url: '/api/admin/admins/update-admin-avatar',
    method: 'post',
    data: data
  })
}

export function getAdmins (data) {
  return request({
    url: '/api/admin/admins/get-admins',
    method: 'post',
    data: data
  })
}

export function showAdmin (data) {
  return request({
    url: '/api/admin/admins/show-admin',
    method: 'post',
    data: data
  })
}

export function storeAdmin (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/api/admin/admins/store-admin',
    method: 'post',
    data: data
  })
}

export function updateAdmin (data) {
  data.password = data.password ? md5(data.password).toUpperCase() : ''
  return request({
    url: '/api/admin/admins/update-admin',
    method: 'post',
    data: data
  })
}
