import * as bookingDatesApi from '@/api/bookingDates/bookingDates'

const bookingDates = {
  state: {
  },
  mutations: {
  },
  actions: {
    getBookingDates ({ commit }, data) {
      return new Promise((resolve, reject) => {
        bookingDatesApi.getBookingDates(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showBookingDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        bookingDatesApi.showBookingDate(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeBookingDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        bookingDatesApi.storeBookingDate(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateBookingDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        bookingDatesApi.updateBookingDate(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyBookingDate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        bookingDatesApi.destroyBookingDate(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default bookingDates
