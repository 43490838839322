import request from '@/utils/request'

export function getRoomOptions (data) {
  return request({
    url: '/api/admin/room-options',
    method: 'post',
    data: data
  })
}

export function showRoomOption (data) {
  return request({
    url: '/api/admin/room-options/show',
    method: 'post',
    data: data
  })
}

export function storeRoomOption (data) {
  return request({
    url: '/api/admin/room-options/store',
    method: 'post',
    data: data
  })
}

export function updateRoomOption (data) {
  return request({
    url: '/api/admin/room-options/update',
    method: 'post',
    data: data
  })
}

export function destroyRoomOption (data) {
  return request({
    url: '/api/admin/room-options/destroy',
    method: 'post',
    data: data
  })
}
