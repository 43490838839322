import request from '@/utils/request'

export function getConfigs (data) {
  return request({
    url: '/api/admin/configs',
    method: 'post',
    data: data
  })
}

export function showConfig (data) {
  return request({
    url: '/api/admin/configs/show',
    method: 'post',
    data: data
  })
}

export function updateConfig (data) {
  return request({
    url: '/api/admin/configs/update',
    method: 'post',
    data: data
  })
}
