export default {
  search_info: 'Last search at {at}, a total of {count} results (search time: {sec} seconds)',
  delete_note: 'Note: This operation cannot be recovered.',
  delete_msg: 'Are you sure you want to delete <b>{name}</b> ?',

  pick_a_date_or_date_range: 'Pick a date or date range',

  incorrect_email_or_password: 'Incorrect email or password',
  email_format_is_incorrect: 'Email format is incorrect',
  wrong_format: 'Wrong format',
  mobile_number_has_been_registered: 'Mobile number has been registered',
  try_again_later: 'Try again later',
  the_verification_code_is_incorrect_or_expired: 'The verification code is incorrect or expired',
  import_file_type_restriction: 'Import file type restriction:{type}',
  import_file_size_limit: 'Import file size limit: {size}',
  limit_on_the_number_of_imported_data: 'Limit on the number of imported data: {count}',
  auto_split_remark: 'If the number of phone numbers in the phone book exceeds {count}, the system will automatically split and add the phone numbers into a new phone book.',
  search_is_limited_to_a_date_range_of_up_to_one_month: 'Search is limited to a date range of up to one month.',

  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thur',
  fri: 'Fri',
  sat: 'Sat',

  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',

  seconds: 'Seconds',
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',

  second: 'Second',
  minute: 'Minute',
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',

  on: 'On',
  off: 'Off',
  login: 'Log in',
  logout: 'Log out',
  login_to_your_account: 'Log in to your account.',
  forgot_password: 'Forgot password ?',
  lang: 'Language',
  main: 'Main',
  dashboard: 'Dashboard',
  profile: 'Profile',
  name: 'Name',
  email: 'Email',
  calling_code: 'Calling code',
  phone: 'Phone',
  cellphone: 'Cellphone',
  telephone: 'Telephone',
  mobile_number: 'Mobile number',
  username: 'Username',
  password: 'Password',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  new_password_confirmation: 'Repeat password',
  generate_password_msg: 'Click the button on the right to generate a random password',
  password_min_msg: 'Password must be at least 8 characters long',
  leave_blank_if_not_modified: 'Leave blank if not modified',
  edit: 'Edit',
  save: 'Save',
  create: 'Create',
  read: 'Read',
  update: 'Update',
  delete: 'Delete',
  export: 'Export',
  ok: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enable: 'Enable',
  disable: 'Disable',
  visible: 'Visible',
  invisible: 'Invisible',
  action: 'Action',
  details: 'Details',
  none: 'None',
  or: ' or ',
  please_select_a: 'Please select a ',
  search_a: 'Search a ',
  change_avatar: 'Change avatar',
  created_successfully: 'Created successfully',
  updated_successfully: 'Updated successfully',
  uploaded_successfully: 'Uploaded successfully',
  deleted_successfully: 'Deleted successfully',
  create_time: 'Create time',
  update_time: 'Update time',
  last_update_time: 'Last update time',
  last_enable_time: 'Last enable time',
  last_status_change_time: 'Last status change time',
  last_used_time: 'Last used time',
  last_data_time: 'Last data time',
  receive_time: 'Receive time',
  required_fields_cannot_be_empty: 'Required fields cannot be empty',
  management: 'Management',
  manager: 'Manager',
  settings: 'Settings',
  system_management: 'System management',
  organization: 'Organization',
  organization_management: 'Organization management',
  roles: 'Permission',
  role_groups: 'Permission groups',
  accounts: 'Accounts',
  account: 'Account',
  account_name: 'Account name',
  admins: 'Administrators',
  search: 'Search',
  advanced_search: 'Advanced search',
  search_mode: 'Search mode',
  create_account: 'Create account',
  edit_account: 'Edit account',
  create_role_group: 'Create permission group',
  edit_role_group: 'Edit permission group',
  set_roles: 'Set permissions',
  permission_denied: 'Permission denied',
  title: 'Title',
  content: 'Content',
  configs: 'Configurations',
  edit_config: 'Edit configuration',
  config_value: 'Configuration value',
  backend_management: 'Backend management',
  type: 'Type',
  sort: 'Sort',
  status: 'Status',

  date_management: 'Date management',
  content_management: 'Content management',
  booking_dates: 'Booking dates',
  booking_date: 'Booking date',
  create_booking_date: 'Create booking date',
  edit_booking_date: 'Edit booking date',
  delete_booking_date: 'Delete booking date',
  holiday_dates: 'Holiday dates',
  holiday_date: 'Holiday date',
  holiday_type: 'Holiday type',
  create_holiday_date: 'Create holiday date',
  delete_holiday_date: 'Delete holiday date',
  start_date: 'Start date',
  end_date: 'End date',
  effective_date: 'Effective date',
  open_date: 'Open date',
  close_date: 'Close date',
  full_date: 'Full date',
  content_management: 'Content management',
  edit_content: 'Edit content',
  room_management: 'Room management',
  room_setting: 'Room setting',
  room_information: 'Room information',
  room_quantity_unit: 'Room quantity unit',
  room_price: 'Room price',
  total_price: 'Total price',
  price: 'Price',
  create_room: 'Create room',
  edit_room: 'Edit room',
  delete_room: 'Delete room',
  rooms: 'Rooms',
  room: 'Room',
  labels: 'Labels',
  labels_comma_separated: 'Labels (comma separated)',
  introduction: 'Introduction',
  description: 'Description',
  lock_room_setting: 'Lock room setting',
  lock_room: 'Lock room',
  create_lock_room: 'Create lock room',
  delete_lock_room: 'Delete lock room',
  quantity_unit_name: 'Quantity unit name',
  quantity_unit: 'Quantity unit',
  quantity: 'Quantity',
  stock: 'Stock',
  combo_discount: 'Combo discount',
  extra_management: 'Extra management',
  extra_setting: 'Extras setting',
  extra_information: 'Extra information',
  extra_quantity_unit: 'Extra quantity unit',
  extra_price: 'Extra price',
  create_extra: 'Create extra',
  edit_extra: 'Edit extra',
  delete_extra: 'Delete extra',
  extras: 'Extras',
  extra: 'Extra', 
  order_management: 'Order management',
  order_search: 'Order search',
  order_room_search: 'Order room search',
  order_extra_search: 'Order extra search',
  orders: 'Orders',
  order: 'Order',
  order_no: 'Order number',
  edit_order: 'Edit order',
  order_information: 'Order information',
  order_detail: 'Order detail',
  payment_detail: 'Payment detail',
  transfer_account: 'Transfer account last 5 digits',
  transfer_price: 'Transfer price',
  transfer_time: 'Transfer time',
  transfer_note: 'Transfer note',
  send_notification: 'Send notification',
  pending: 'Pending',
  completed: 'Completed',
  confirming: 'Payment confirming',
  refunding: 'Refund applying',
  refunded: 'Refunded',
  cancelled: 'Cancelled',
  expired: 'Expired',
  closed: 'Closed',
  paid: 'Paid',
  booking_name: 'Booking name',
  booking_phone: 'Booking phone',
  booking_note: 'Booking note',
  agent_note: 'Agent note',
  check_in: 'Check in',
  check_out: 'Check out',
  images: 'Images',
  image: 'Image',
  upload_image: 'Upload image',
  image_crop: 'Image crop',
  drag_image_msg: 'Click image vertically to change order',
  drag_text_msg: 'Click text vertically to change order',
  options: 'Options',
  room_options: 'Room options',
  room_option: 'Room option',
  extra_options: 'Extra options',
  extra_option: 'Extra option',
  create_option: 'Create option',
  edit_option: 'Edit option',
  delete_option: 'Delete option',
  sub_options: 'Sub options',
  create_sub_option: 'Create sub option',
  edit_sub_option: 'Edit sub option',
  delete_sub_option: 'Delete sub option',
  required: 'Required',
  room_images: 'Room image',
  extra_images: 'Extra image',
  date_selection_mode: 'Date selection mode',
  date_calculation_mode: 'Date calculation mode',
  range_day_minus_one: 'Range days minus one',
  single_day: 'Single day',
}
