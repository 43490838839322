export default {
  search_info: '最後搜尋於 {at}，共有 {count} 項結果（搜尋時間：{sec}秒）',
  delete_note: '注意：此操作無法恢復',
  delete_msg: '您確定要刪除 <b>{name}</b> 嗎？',

  pick_a_date_or_date_range: '選擇一個日期或日期範圍',

  incorrect_email_or_password: '錯誤的Email或密碼',
  email_format_is_incorrect: '電子郵件格式不正確',
  wrong_format: '格式錯誤',
  mobile_number_has_been_registered: '手機號碼已被註冊',
  try_again_later: '稍後再試',
  the_verification_code_is_incorrect_or_expired: '驗證碼不正確或已過期',
  import_file_type_restriction: '匯入檔案類型限制：{type}',
  import_file_size_limit: '匯入檔案大小限制：{size}',
  limit_on_the_number_of_imported_data: '匯入資料筆數限制：{count} 筆',
  auto_split_remark: '如果電話簿中的電話號碼超過 {count} 個，系統將自動拆分電話號碼至新的電話簿中。',
  search_is_limited_to_a_date_range_of_up_to_one_month: '只開放搜尋一個月內的資料。',

  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',

  jan: '1 月',
  feb: '2 月',
  mar: '3 月',
  apr: '4 月',
  may: '5 月',
  jun: '6 月',
  jul: '7 月',
  aug: '8 月',
  sep: '9 月',
  oct: '10 月',
  nov: '11 月',
  dec: '12 月',

  seconds: '秒',
  minutes: '分',
  hours: '時',
  days: '日',
  weeks: '週',
  months: '月',
  years: '年',

  second: '秒',
  minute: '分',
  hour: '時',
  day: '日',
  week: '週',
  month: '月',
  year: '年',

  on: '開',
  off: '關',
  login: '登入',
  logout: '登出',
  login_to_your_account: '登入您的帳戶',
  forgot_password: '忘記密碼？',
  lang: '語言',
  main: '主頁',
  dashboard: '儀表板',
  profile: '個人資料',
  name: '名稱',
  email: '電子郵件',
  calling_code: '國家代碼',
  phone: '電話',
  cellphone: '手機',
  telephone: '市話',
  mobile_number: '手機號碼',
  username: '使用者名稱',
  password: '密碼',
  change_password: '更改密碼',
  current_password: '當前密碼',
  new_password: '新密碼',
  new_password_confirmation: '重複輸入密碼',
  generate_password_msg: '點擊右方按鈕以生成隨機密碼',
  password_min_msg: '密碼長度必須至少為 8 個字符',
  leave_blank_if_not_modified: '如不修改則留空',
  edit: '編輯',
  save: '儲存',
  create: '新增',
  read: '讀取',
  update: '更新',
  delete: '刪除',
  export: '匯出',
  ok: '確定',
  cancel: '取消',
  yes: '是',
  no: '否',
  enable: '啟用',
  disable: '禁用',
  visible: '可見',
  invisible: '不可見',
  action: '操作',
  details: '詳細資訊',
  none: '無',
  or: '或',
  please_select_a: '請選擇一個',
  search_a: '搜尋一個',
  change_avatar: '變更頭像',
  created_successfully: '新增成功',
  updated_successfully: '更新成功',
  uploaded_successfully: '上傳成功',
  deleted_successfully: '刪除成功',
  create_time: '新增時間',
  update_time: '更新時間',
  last_update_time: '最近更新時間',
  last_enable_time: '最近啟用時間',
  last_status_change_time: '最近狀態變更時間',
  last_used_time: '最近使用時間',
  last_data_time: '最近資料時間',
  receive_time: '接收時間',
  required_fields_cannot_be_empty: '必填欄位不能為空',
  management: '管理',
  manager: '管理者',
  settings: '設定',
  system_management: '系統管理',
  organization: '組織',
  organization_management: '組織管理',
  roles: '權限',
  role_groups: '權限群組',
  accounts: '帳號',
  account: '帳號',
  account_name: '帳號名稱',
  admins: '管理員',
  search: '搜尋',
  advanced_search: '進階搜尋',
  search_mode: '搜尋模式',
  create_account: '新增帳號',
  edit_account: '編輯帳號',
  create_role_group: '新增權限群組',
  edit_config: '編輯設置',
  config_value: '設置值',
  backend_management: '後台管理',
  type: '類型',
  sort: '排序',
  status: '狀態',

  date_management: '日期管理',
  booking_dates: '預訂日期',
  booking_date: '預訂日期',
  create_booking_date: '新增預訂日期',
  edit_booking_date: '編輯預訂日期',
  delete_booking_date: '刪除預訂日期',
  edit_role_group: '編輯權限群組',
  set_roles: '設定權限',
  permission_denied: '權限不足',
  title: '標題',
  content: '內容',
  configs: '系統設置',
  holiday_dates: '假期日期',
  holiday_date: '假期日期',
  holiday_type: '假期類型',
  create_holiday_date: '新增假期日期',
  delete_holiday_date: '刪除假期日期',
  start_date: '開始日期',
  end_date: '結束日期',
  effective_date: '生效日期',
  open_date: '開放日',
  close_date: '休息日',
  full_date: '滿房日',
  content_management: '內容管理',
  edit_content: '編輯內容',
  room_management: '客房管理',
  room_setting: '房型設定',
  room_information: '房型資訊',
  room_quantity_unit: '房型數量單位',
  room_price: '房型價格',
  total_price: '總價格',
  price: '價格',
  create_room: '新增房型',
  edit_room: '編輯房型',
  delete_room: '刪除房型',
  rooms: '房型',
  room: '房型',
  labels: '標籤',
  labels_comma_separated: '標籤（已逗號分隔）',
  introduction: '簡介',
  description: '描述',
  lock_room_setting: '鎖房設定',
  lock_room: '鎖房',
  create_lock_room: '新增鎖房',
  delete_lock_room: '刪除鎖房',
  quantity_unit_name: '數量單位名稱',
  quantity_unit: '數量單位',
  quantity: '數量',
  stock: '庫存數量',
  combo_discount: '組合折扣',
  extra_management: '行程管理',
  extra_setting: '行程設定',
  extra_information: '行程資訊',
  extra_quantity_unit: '行程數量單位',
  extra_price: '行程價格',
  create_extra: '新增行程',
  edit_extra: '編輯行程',
  delete_extra: '刪除行程',
  extras: '行程',
  extra: '行程', 
  order_management: '訂單管理',
  order_search: '訂單查詢',
  order_room_search: '預訂房型查詢',
  order_extra_search: '預訂行程查詢',
  orders: '訂單',
  order: '訂單',
  order_no: '訂單編號',
  edit_order: '編輯訂單',
  order_information: '訂單資訊',
  order_detail: '訂單明細',
  payment_detail: '付款明細',
  transfer_account: '轉出帳號後5碼',
  transfer_price: '轉帳金額',
  transfer_time: '轉帳時間',
  transfer_note: '轉帳備註',
  send_notification: '發送通知',
  pending: '待付款',
  completed: '已成立',
  confirming: '付款確認中',
  refunding: '退款申請中',
  refunded: '已退款',
  cancelled: '已取消',
  expired: '已過期',
  closed: '已結束',
  paid: '已付款',
  booking_name: '預訂姓名',
  booking_phone: '預訂電話',
  booking_note: '預訂備註',
  agent_note: '後台備註',
  check_in: '入住',
  check_out: '退房',
  images: '圖片',
  image: '圖片',
  room_images: '房型圖片',
  upload_image: '上傳圖片',
  image_crop: '圖片裁剪',
  drag_image_msg: '點擊圖片垂直拖曳以變更順序',
  drag_text_msg: '點擊文本垂直拖曳以變更順序',
  options: '選項',
  room_options: '房型選項',
  room_option: '房型選項',
  extra_options: '行程選項',
  extra_option: '行程選項',
  create_option: '新增選項',
  edit_option: '編輯選項',
  delete_option: '刪除選項',
  sub_options: '子選項',
  create_sub_option: '新增子選項',
  edit_sub_option: '編輯子選項',
  delete_sub_option: '刪除子選項',
  required: '必填',
  extra_images: '行程圖片',
  date_selection_mode: '日期選擇模式',
  range: '範圍',
  single: '單選',
  date_calculation_mode: '日期計算模式',
  range_day_minus_one: '選擇範圍天數扣1',
  single_day: '選擇單日',
}
