import * as orderRoomsApi from '@/api/orders/orderRooms'

const orderRooms = {
  state: {
  },
  mutations: {
  },
  actions: {
    getOrderRooms ({ commit }, data) {
      return new Promise((resolve, reject) => {
        orderRoomsApi.getOrderRooms(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default orderRooms
